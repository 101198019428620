import React, { useState } from 'react';
import { assetConstants } from '@chainflip/utils/chainflip';
import { formatUsdValue } from '@chainflip/utils/number';
import { BigNumber } from 'bignumber.js';
import { startOfHour, subDays } from 'date-fns';
import { flip$ } from '@/shared/assets/tokens';
import { Card } from '@/shared/components';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { useHistoricalPrice } from '@/shared/hooks/useHistoricalPrice';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import {
  ChartIcon,
  CoinsIcon,
  DollarIcon,
  FireIcon,
  LockIcon,
  SwapIcon,
} from '@/shared/icons/large';
import { BoltIcon, TrendingDownIcon, TrendingUpIcon } from '@/shared/icons/small';
import {
  FLIP_SYMBOL,
  TokenAmount,
  chainflipAssetMap,
  formatWithCommas,
  formatWithNumeral,
} from '@/shared/utils';
import { useFlipContract } from 'packages/block-explorer/hooks/useFlipContract';
import { getCirculatingSupplyQuery } from '../../graphql/cache/protocol';
import { getProtocolStatsQuery } from '../../graphql/explorer/protocol';

const CardWrapper = ({
  title,
  icon,
  footer,
  children,
  isLoading,
}: {
  title: string;
  icon: JSX.Element;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  isLoading?: boolean;
}) => (
  <Card
    className="[&>div]:justify-center [&>div]:md:justify-start"
    title={title}
    icon={icon}
    loading={isLoading}
    skeletonLineWidth={100}
    footer={<div className="flex justify-center gap-x-1 md:justify-start">{footer}</div>}
  >
    <span className="font-aeonikMedium text-16 text-cf-light-4 md:text-24">{children}</span>
  </Card>
);

const ProtocolStatCards = () => {
  const [today] = useState<Date | null>(new Date());
  const { price } = useTokenPrice(chainflipAssetMap.Flip);
  const { totalFundedFlip, isLoading: isTotalLockedLoading } = useFlipContract();
  const { historicalPrice } = useHistoricalPrice(
    flip$,
    subDays(startOfHour(today ?? 0), 1).toISOString(),
  );

  const { data: protocolStats, isLoading: isProtocolStatsLoading } = useGqlQuery(
    getProtocolStatsQuery,
    {
      variables: {
        maxTimestamp: subDays(today ?? 0, 1).toISOString(),
      },
      enabled: Boolean(today),
    },
  );

  const { data: circulation, isLoading: isCirculationLoading } = useGqlQuery(
    getCirculatingSupplyQuery,
    {
      context: { clientName: 'statechainCache' },
    },
  );

  const lockedFlipUsdValue = new BigNumber(price ?? 0)
    .times(totalFundedFlip?.toFixed(2) ?? 0)
    .toFixed(2);

  const circulatingSupply = circulation?.circulation?.circulatingSupply
    ? `${formatWithNumeral(
        new TokenAmount(
          circulation?.circulation?.circulatingSupply,
          assetConstants.Flip.decimals,
        ).toFixed(2),
      )} ${FLIP_SYMBOL}`
    : 'N/A';

  const totalSupply = formatWithNumeral(
    new TokenAmount(
      circulation?.circulation?.totalSupply || 0,
      assetConstants.Flip.decimals,
    ).toFixed(2),
  );

  const totalBurn = new TokenAmount(
    protocolStats?.currentBurn?.aggregates?.sum?.totalAmount ?? 0,
    assetConstants.Flip.decimals,
  );

  const totalBoostBurn = new TokenAmount(
    protocolStats?.currentBurn?.aggregates?.sum?.boostAmount ?? 0,
    assetConstants.Flip.decimals,
  );

  const historicalBurn = new TokenAmount(
    protocolStats?.historicalBurn?.aggregates?.sum?.totalAmount ?? 0,
    assetConstants.Flip.decimals,
  );

  const burnDelta = totalBurn.sub(historicalBurn);

  const volume = BigNumber.sum(
    protocolStats?.oneLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
    protocolStats?.twoLegSwaps?.aggregates?.sum?.intermediateValueUsd ?? 0,
    protocolStats?.twoLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
  );

  const historicalVolume = BigNumber.sum(
    protocolStats?.historicalOneLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
    protocolStats?.historicalTwoLegSwaps?.aggregates?.sum?.intermediateValueUsd ?? 0,
    protocolStats?.historicalTwoLegSwaps?.aggregates?.sum?.swapOutputValueUsd ?? 0,
  );

  const priceDelta = new BigNumber(price ?? 0)
    .minus(historicalPrice?.FLIP?.usdPrice ?? 0)
    .dividedBy(historicalPrice?.FLIP?.usdPrice ?? 0)
    .times(100);

  const volumeDelta = volume.minus(historicalVolume);

  const two = new BigNumber(2);

  const count = two
    .times(protocolStats?.twoLegSwaps?.aggregates?.distinctCount?.id ?? 0)
    .plus(protocolStats?.oneLegSwaps?.aggregates?.distinctCount?.id ?? 0);

  const historicalCount = two
    .times(protocolStats?.historicalTwoLegSwaps?.aggregates?.distinctCount?.id ?? 0)
    .plus(protocolStats?.historicalOneLegSwaps?.aggregates?.distinctCount?.id ?? 0);

  const countDiff = formatWithCommas(count.minus(historicalCount));

  const isFlipPriceLoading = price === undefined || historicalPrice === null;

  return (
    <div className="grid grid-cols-2 gap-5 text-center md:grid-cols-3 md:text-left">
      <CardWrapper
        title="Volume / All time"
        icon={<ChartIcon className="h-[16px] w-[16px] text-cf-green-1 md:h-[unset] md:w-[unset]" />}
        isLoading={isProtocolStatsLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{formatUsdValue(volumeDelta, false)}</span>
          </>
        }
      >
        {formatUsdValue(volume, false)}
      </CardWrapper>
      <CardWrapper
        title="Swaps / All time"
        icon={<SwapIcon className="h-[16px] w-[16px] text-cf-orange-2 md:h-[unset] md:w-[unset]" />}
        isLoading={isProtocolStatsLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{countDiff} swaps</span>
          </>
        }
      >
        {formatWithCommas(count)}
      </CardWrapper>
      <CardWrapper
        title={`Staked ${FLIP_SYMBOL}`}
        isLoading={isTotalLockedLoading}
        icon={<LockIcon className="h-[16px] w-[16px] text-cf-blue-2 md:h-[unset] md:w-[unset]" />}
        footer={<div className="text-cf-light-2">{formatUsdValue(lockedFlipUsdValue, false)}</div>}
      >
        {formatWithNumeral(totalFundedFlip?.toFixed(2) ?? 0)} {FLIP_SYMBOL}
      </CardWrapper>
      <CardWrapper
        title="FLIP Price"
        icon={
          <DollarIcon className="h-[16px] w-[16px] text-cf-green-3 md:h-[unset] md:w-[unset]" />
        }
        isLoading={isFlipPriceLoading}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            {priceDelta.isNegative() ? (
              <TrendingDownIcon className="text-cf-red-2" />
            ) : (
              <TrendingUpIcon className="text-cf-green-3" />
            )}
            <span className={priceDelta.isNegative() ? 'text-cf-red-2' : 'text-cf-green-3'}>
              {priceDelta.toFixed(2)}%
            </span>
          </>
        }
      >
        {formatUsdValue(price) ?? ''}
      </CardWrapper>
      <CardWrapper
        title={`Total ${FLIP_SYMBOL} Burned`}
        isLoading={isProtocolStatsLoading}
        icon={<FireIcon className="h-[16px] w-[16px] text-cf-red-2 md:h-[unset] md:w-[unset]" />}
        footer={
          <>
            <span className="text-cf-light-2">Last 24H</span>
            <TrendingUpIcon className="text-cf-green-3" />
            <span className="text-cf-green-3">+{burnDelta.toFixed(2)}</span>
          </>
        }
      >
        <div className="flex items-center justify-center gap-x-0.5 md:justify-normal">
          <div className="pr-0.5 font-aeonikMedium text-16 font-medium text-cf-light-4 md:text-24">
            {formatWithNumeral(totalBurn.toFixed(2))}
          </div>
          {totalBoostBurn.gt(0) && (
            <>
              <BoltIcon className="text-cf-pink-1" />
              <span className="font-aeonikMono text-12 text-cf-pink-1">
                ({formatWithNumeral(totalBoostBurn.toFixed(2))})
              </span>
            </>
          )}
        </div>
      </CardWrapper>
      <CardWrapper
        title="Circulating Supply"
        icon={<CoinsIcon className="h-[16px] w-[16px] text-cf-blue-1 md:h-[unset] md:w-[unset]" />}
        isLoading={isCirculationLoading}
        footer={
          circulatingSupply !== 'N/A' && (
            <span className="text-cf-light-2">
              Out of <span className="ml-0.5 text-cf-light-3">{totalSupply}</span>
            </span>
          )
        }
      >
        {circulatingSupply}
      </CardWrapper>
    </div>
  );
};

export default ProtocolStatCards;
