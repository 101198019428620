import { getLatestBlocksQuery } from '@/shared/graphql/explorer/queries';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import HeroSmall from '../assets/svg/hero-small.svg';
import Hero from '../assets/svg/hero.svg';
import BlocksTable from '../components/BlockPage/BlocksTable';
import LatestEventsTable from '../components/HomePage/LatestEventsTable';
import MetadataCards from '../components/HomePage/MetadataCards';
import ProtocolStatsCards from '../components/HomePage/ProtocolStatCards';
import SearchBar from '../components/HomePage/SearchBar';
import { Layout } from '../components/Layout';
import LatestSwapsTable from '../components/SwapsPage/LatestSwapsTable';

export default function Home(): JSX.Element {
  const { data, isLoading } = useGqlQuery(getLatestBlocksQuery, { refetchInterval: 5000 });

  return (
    <Layout
      hero={
        <>
          <HeroSmall className="absolute w-full md:hidden" />
          <Hero className="absolute mt-[-50px] hidden w-full md:block" />
        </>
      }
    >
      <div className="flex flex-col space-y-20">
        <div className="flex flex-col items-center justify-center space-y-10 md:min-h-[300px]">
          <div className="font-aeonikMedium text-[32px] text-white md:text-[49px]">
            Explore the State Chain
          </div>
          <div className="w-full md:w-[544px]">
            <SearchBar />
          </div>
        </div>
        <ProtocolStatsCards />
        <LatestSwapsTable preview />
        <MetadataCards blockList={data?.latestBlocks?.nodes || []} />
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
          <LatestEventsTable />
          <BlocksTable blockList={data?.latestBlocks?.nodes || []} isLoading={isLoading} preview />
        </div>
      </div>
    </Layout>
  );
}
