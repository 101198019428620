import { useRouter } from 'next/router';
import Button from '@/shared/components/flip-ui-kit/Button';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import { type BlockListBlockFragment } from '@/shared/graphql/generated/explorer/graphql';
import BlockRow from './BlockRow';

interface BlocksTableParams {
  blockList: BlockListBlockFragment[];
  isLoading: boolean;
  preview?: boolean;
}

const BlocksTable = ({ blockList, isLoading, preview = false }: BlocksTableParams) => {
  const router = useRouter();
  const tableRows = blockList.map((block) => (
    <BlockRow key={block.id} block={block} preview={preview} />
  ));

  return (
    <div className="text-white">
      <div className="my-6 flex items-center">
        <span className="text-20 text-cf-white">Latest Blocks</span>
        <div className="ml-auto">
          <Button
            type="secondary-standard"
            iconPos="right"
            onClick={() => router.push('/blocks')}
            size="small"
          >
            View All
          </Button>
        </div>
      </div>
      <TableV2
        columns={[
          'Block',
          'Events',
          ...(preview ? ['Extrinsics'] : ['Extrinsics', 'Author']),
          { name: 'Age', alignment: 'right' },
        ]}
        isLoading={isLoading}
        rows={tableRows}
      />
    </div>
  );
};

export default BlocksTable;
