import { gql } from '@/shared/graphql/generated/cache';

export const getCirculatingSupplyQuery = gql(/* GraphQL */ `
  query GetCirculatingSupply {
    circulation: circulationById(id: 1) {
      circulatingSupply
      totalSupply
    }
  }
`);
