import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import {
  getExtrinsicSuccessMetadataIdQuery,
  getRecentEventsByBlockQuery,
} from '../graphql/explorer/events';

export const useRecentEventsByBlock = (first = 2) => {
  const { data, isLoading: isMetadataLoading } = useGqlQuery(getExtrinsicSuccessMetadataIdQuery);
  const metadataId = data?.metadata?.nodes?.[0]?.id;

  const { data: recentEvents, isLoading: isEventsLoading } = useGqlQuery(
    getRecentEventsByBlockQuery,
    {
      variables: {
        excludeMetadataId: metadataId as number,
        first,
      },
      enabled: Boolean(metadataId),
      refetchInterval: 6_000,
    },
  );

  const reducedEvents = recentEvents?.blocks?.nodes.reduce(
    (res, block) => {
      const eventRollup = block.eventsByBlockId.nodes.reduce(
        (acc, event) => {
          const { name } = event.metadata;
          acc[name] ??= {
            count: 0,
            blockId: event.blockId,
            indexInBlock: event.indexInBlock,
          };
          acc[name].count += 1;
          return acc;
        },
        {} as Record<string, { blockId: number; indexInBlock: number; count: number }>,
      );

      const sortedEventRollup = Object.fromEntries(
        Object.entries(eventRollup).sort(([, a], [, b]) => b.count - a.count),
      );

      return { ...res, [block.id]: sortedEventRollup };
    },
    {} as Record<string, Record<string, { blockId: number; indexInBlock: number; count: number }>>,
  );

  return {
    recentEvents: reducedEvents,
    isLoading: isMetadataLoading || isEventsLoading,
  };
};
